import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOAD_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  user: {},
  isUserLogout: true,
  token: localStorage.getItem("token"),
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      state = {
        ...state,
        loading: false,
        user: {
          ...action.payload.user,
          ApplesCount: action.payload.ApplesCount,
          soldPercentage: action.payload.soldPercentage,
          ApplesSoldCount: action.payload.ApplesSoldCount,
          ApplesCountq: action.payload.ApplesCountq,
          soldPercentageq: action.payload.soldPercentageq,
          ApplesSoldCountq: action.payload.ApplesSoldCountq,
          GoogleCount: action.payload.GoogleCount,
          soldPercentageGoogle: action.payload.soldPercentageGoogle,
          GoogleSoldCount: action.payload.GoogleSoldCount,

          FacebookCount: action.payload.FacebookCount,
          soldPercentageFacebook: action.payload.soldPercentageFacebook,
          FacebookSoldCount: action.payload.FacebookSoldCount,

          InstagramCount: action.payload.InstagramCount,
          soldPercentageInstagram: action.payload.soldPercentageInstagram,
          InstagramSoldCount: action.payload.InstagramSoldCount,

          SnapchatCount: action.payload.SnapchatCount,
          soldPercentageSnapchat: action.payload.soldPercentageSnapchat,
          SnapchatSoldCount: action.payload.SnapchatSoldCount,

          PlaystationCount: action.payload.PlaystationCount,
          soldPercentagePlaystation: action.payload.soldPercentagePlaystation,
          PlaystationSoldCount: action.payload.PlaystationSoldCount,
        },
        token: action.payload.token,
        isUserLogout: false,
      };
      break;
    case LOAD_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: {
          ...action.payload.user,
          ApplesCount: action.payload.ApplesCount,
          soldPercentage: action.payload.soldPercentage,
          ApplesSoldCount: action.payload.ApplesSoldCount,
          ApplesCountq: action.payload.ApplesCountq,
          soldPercentageq: action.payload.soldPercentageq,
          ApplesSoldCountq: action.payload.ApplesSoldCountq,
          GoogleCount: action.payload.GoogleCount,
          soldPercentageGoogle: action.payload.soldPercentageGoogle,
          GoogleSoldCount: action.payload.GoogleSoldCount,

          FacebookCount: action.payload.FacebookCount,
          soldPercentageFacebook: action.payload.soldPercentageFacebook,
          FacebookSoldCount: action.payload.FacebookSoldCount,

          InstagramCount: action.payload.InstagramCount,
          soldPercentageInstagram: action.payload.soldPercentageInstagram,
          InstagramSoldCount: action.payload.InstagramSoldCount,

          SnapchatCount: action.payload.SnapchatCount,
          soldPercentageSnapchat: action.payload.soldPercentageSnapchat,
          SnapchatSoldCount: action.payload.SnapchatSoldCount,

          PlaystationCount: action.payload.PlaystationCount,
          soldPercentagePlaystation: action.payload.soldPercentagePlaystation,
          PlaystationSoldCount: action.payload.PlaystationSoldCount,
        },
        isUserLogout: false,
      };
      break;
    case LOGOUT_USER:
      state = { ...state, loading: true };
      break;
    case LOGOUT_USER_SUCCESS:
      localStorage.removeItem("token");
      state = {
        ...state,
        isUserLogout: true,
        loading: false,
        user: {},
        token: null,
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        // isUserLogout: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
